<template>
  <div class="">
    <div class="grid grid-cols-12 mb-3 gap-2">
      <div class="col-span-2">
        <button class="btn btn-success btn-sm" @click="listBiayaMhs">
          <cloud-snow-icon class="mr-2"></cloud-snow-icon>
          Hitung Biaya
        </button>
      </div>
      <div class="col-span-4">
        <div class="flex flex-col sm:flex-row">
          <div class="flex-initial">
            <label class="form-label mt-2">Cari Mahasiswa </label>
          </div>
          <div class="flex-1 ml-2">
            <input
              v-model="carinama_txt"
              type="text"
              class="form-control"
              placeholder="Masukkan Nama Mahasiswa"
            />
          </div>
          <div class="flex-initial ml-2">
            <button class="btn btn-default btn-sm" @click="cariMhs">
              <search-icon class="mr-2"></search-icon>
              Cari
            </button>
          </div>
        </div>
      </div>
      <div class="col-span-2 ml-5">
        <div class="form-check mt-2">
          <input
            id="modecicil"
            v-model="modecicilan"
            type="checkbox"
            class="form-check-input w-auto"
          />
          <label class="form-check-label w-full" for="modecicil"
            >Mahasiswa Cicilan
          </label>
        </div>
      </div>
      <div class="col-span-2 ml-5">
        <select v-model="modeMhsAktif" class="form-select sm:mr-2 mb-2">
          <option value="A">Aktif</option>
          <option value="T">Non Aktif</option>
          <option value="S">Semua</option>
        </select>
      </div>
      <div class="col-span-2 ml-5">
        <select v-model="modekelas" class="form-select sm:mr-2 mb-2">
          <option value="A">Semua</option>
          <option value="P">Pagi</option>
          <option value="S">Siang</option>
          <option value="K">K. Karyawan</option>
          <option value="I">Internasonal</option>
        </select>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr class="text-center">
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            rowspan="2"
          >
            <input v-model="tagihSemua" type="checkbox" />
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            rowspan="2"
          >
            Mahasiswa
          </th>

          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            VA
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-normal w-48 max-w-xs"
            rowspan="2"
          >
            Tahun (Semester)
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-normal w-48 max-w-xs"
            rowspan="2"
          >
            Status Aktif Semester Lalu
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-normal w-48 max-w-xs"
            rowspan="2"
          >
            Kelas Kuliah
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            :colspan="jenisBiayaSelected?.length"
          >
            Pembiayaan
          </th>

          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Beasiswa
          </th>

          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Cicilan
          </th>

          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Total Biaya
          </th>
          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Total Tagihan
          </th>

          <th
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap w-48 max-w-xs"
            rowspan="2"
          >
            Status Tagihan
          </th>
        </tr>

        <tr class="text-center">
          <th
            v-for="jenis in jenisBiayaSelected"
            :key="jenis.id"
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            rowspan="2"
          >
            {{ jenis.nama }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in listMhs"
          :key="index"
          :class="item.buat_tagihan ? 'bg-green-50' : 'bg-red-50'"
        >
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-center text-xs w-40"
          >
            <input
              v-model="item.buat_tagihan"
              type="checkbox"
              :disabled="item.totalbiaya == 0"
              @click="setTagih(item)"
            />
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs w-40"
          >
            <div class="whitespace-nowrap">{{ item?.nipd }}</div>
            <div class="whitespace-normal font-medium">{{ item?.nm_pd }}</div>
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ item?.no_va }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs w-40"
          >
            {{ item?.tahun }}
            ({{ item?.semester_kuliah }})
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-center text-xs w-40"
          >
            {{ item.kuliah_mhs?.id_stat_mhs }}
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-center text-xs w-40"
          >
            <div>{{ item.ket_kelompok_kelas }}</div>
          </td>

          <td
            v-for="jenis in jenisBiayaSelected"
            :key="jenis.id"
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{
              $h.IDR(
                item.biayakuliah?.find((f) => {
                  return f?.id_jenis_biaya == jenis.id;
                })?.biaya
              )
            }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs w-40"
          >
            <div v-for="b in item.beasiswa" :key="b.id" class="form-check">
              <input
                v-if="b.validasi == 0"
                :id="'pakaib-' + b.id"
                v-model="b.pakai"
                type="checkbox"
                class="form-check-input w-auto"
                @change="pakaiBeasiswa(b.id, item)"
              />
              <x-icon v-else class="text-red-600 w-4"></x-icon>
              <label
                class="form-check-label"
                :for="'pakaib-' + b.id"
                :title="b.validasi == 1 ? 'Beasiswa sudah digunakan' : ''"
                ><b>{{ b.jenis_biaya }}</b> : {{ $h.IDR(b.nominal) }}
              </label>
            </div>
          </td>

          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            <div v-if="item.cicilan.length > 0">
              <div v-for="b in item.cicilan" :key="b.id" class="form-check">
                <label
                  >{{ b.jumlahcicilan }} x (<b>{{ b.jenis_biaya }}</b
                  >)</label
                >
              </div>
            </div>
            <div v-else>Tidak</div>
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            {{ $h.IDR(item?.totalbiaya) }}
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
          >
            <div v-if="item?.status_tagihan == 'T'">
              {{ $h.IDR(item?.tagjupiter?.Tagihan) }}
            </div>
            <div v-else>
              {{ $h.IDR(item?.totalrp) }}
            </div>
          </td>
          <td
            class="border border-b-2 dark:border-indigo-600 whitespace-normal text-xs w-40"
          >
            <div
              v-if="
                item?.status_tagihan == 'Y' &&
                item?.status_validasi_tagihan == 'T'
              "
              class="py-1 px-2 rounded-full text-xs bg-green-400 text-white text-center cursor-pointer font-medium"
            >
              Ada Transaksi
            </div>
            <div
              v-if="
                item?.status_tagihan == 'Y' &&
                item?.status_validasi_tagihan == 'Y'
              "
              class="py-1 px-2 rounded-full text-xs bg-green-500 text-white text-center cursor-pointer font-medium"
            >
              Telah Divalidasi
            </div>
            <div
              v-if="item?.status_tagihan == 'T'"
              class="py-1 px-2 rounded-full text-xs bg-red-500 text-white text-center cursor-pointer font-medium"
            >
              Belum Dibayar
            </div>
            <div
              v-if="item?.status_tagihan == null"
              class="py-1 px-2 rounded-full text-xs bg-gray-500 text-white text-center cursor-pointer font-medium"
            >
              Belum Ada Tagihan
            </div>
            <button
              v-if="
                model.subcomp != null &&
                model.produk != null &&
                item?.status_tagihan == 'T'
              "
              class="btn btn-sm btn-warning py-0 mt-2"
              @click="hapusTagihan(item)"
            >
              <Trash2Icon class="w-4 mr-2" />
              Hapus Tagihan
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="listMhsAktif?.data?.length > 0 && !modecicilan"
      class="grid grid-cols-12 gap-2 mt-3"
    >
      <div class="col-span-2">
        <label>Page Size</label>
        <select
          v-model="size"
          class="form-control w-auto ml-2"
          @change="reloadData"
        >
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="true">All</option>
        </select>
      </div>
      <div class="col-span-1">
        <label>Page</label>
        <select
          v-model="page"
          class="form-control w-auto ml-2"
          @change="reloadData"
        >
          <option v-for="n in listMhsAktif?.last_page" :key="n" :value="n">
            {{ n }}
          </option>
        </select>
      </div>
    </div>

    <p class="mt-3">
      Total Mahasiswa Ditampilkan : <b>{{ listMhs?.length }}</b> dari
      <b>{{ listMhsAktif?.total }}</b> Total Mahasiswa
    </p>
    <p class="mt-0">
      Mahasiswa Aktif :
      <b>{{
        listMhs?.filter((f) => {
          return f.kuliah_mhs.id_stat_mhs === "A";
        })?.length
      }}</b>
    </p>
    <p class="mt-0">
      Mahasiswa Kampus Merdeka :
      <b>{{
        listMhs?.filter((f) => {
          return f.kuliah_mhs.id_stat_mhs === "M";
        })?.length
      }}</b>
    </p>
    <p class="mt-0">Memiliki Beasiswa : <b>0</b></p>
  </div>
</template>

<script>
import { HTTPKEU } from "../../../services/http";

export default {
  name: "TableMhsAktif",
  props: {
    listMhsAktif: {
      type: Object,
      default: null,
    },
    biayaKuliah: {
      type: Array,
      default: null,
    },
    biayaAdm: {
      type: [String, Number],
      default: 3000,
    },
    listTagihanJupiter: {
      type: Array,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
    jenisBiayaList: {
      type: Array,
      default: null,
    },
    idJenisBiaya: {
      type: Array,
      default: null,
    },
    biayaMhs: {
      type: Array,
      default: null,
    },
    jenisBiayaSelected: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      listMhsAll: [],
      listMhs: [],
      tagihSemua: true,
      jenisBiaya: [],
      size: 50,
      page: 1,
      biaya: [],
      modecicilan: false,
      carinama_txt: null,
      modeMhsAktif: "S",
      modekelas: "A",
      mhsCicilSelected: null,
      dataMhsCicil: null,
    };
  },
  computed: {},
  watch: {
    listMhsAktif: {
      handler: function () {
        this.listBiayaMhs();
      },
      immediate: true,
    },
    listTagihanJupiter: {
      handler: function () {
        this.listBiayaMhsTagihan();
      },
      immediate: true,
    },
    model: {
      handler: function () {},
      immediate: true,
    },
    idJenisBiaya: {
      handler: function () {},
      immediate: true,
    },
    jenisBiayaSelected: {
      handler: function () {
        this.listBiayaMhs();
      },
      immediate: true,
    },
    biayaMhs: {
      handler: function () {
        this.biaya = this.biayaMhs;
        this.listBiayaMhs();
      },
      immediate: true,
      deep: true,
    },
    size: {
      handler: function () {
        this.page = 1;
      },
      deep: true,
    },
    modecicilan: {
      handler: function () {
        if (this.modecicilan) {
          this.listMhs = this.listMhsAll.filter((f) => {
            return f.cicilan?.length > 0;
          });
        } else {
          this.listMhs = this.listMhsAll.filter((f) => {
            return f.cicilan?.length === 0;
          });
        }
        this.$emit("listBiayaMhs", this.listMhs);
        this.$emit("modeCicilan", this.modecicilan);
      },
      deep: true,
    },
    modeMhsAktif: {
      handler: function () {
        this.cariMhs();
      },
      deep: true,
    },
    modekelas: {
      handler: function () {
        this.cariMhs();
        //this.$emit("listBiayaMhs", this.listMhs);
      },
      deep: true,
    },
    tagihSemua: {
      handler: function (h) {
        this.listMhs = this.listMhsAll.map((a) => {
          if (a.totalbiaya == 0 && h == true) {
            return {
              ...a,
              buat_tagihan: false,
            };
          } else {
            return {
              ...a,
              buat_tagihan: h,
            };
          }
        });
        this.listMhsAll = this.listMhsAll.map((a) => {
          if (a.totalbiaya == 0 && h == true) {
            return {
              ...a,
              buat_tagihan: false,
            };
          } else {
            return {
              ...a,
              buat_tagihan: h,
            };
          }
        });
        this.cariMhs();
      },
      deep: true,
    },

    mhsCicilSelected: {
      handler: function (h) {
        if (h) {
          this.dataMhsCicil = this.listMhs.find((f) => {
            return f.nipd == h;
          });
        }

        console.log(this.dataMhsCicil);
        /*this.dataCama.no_va = this.dataCama.no_ujian;
        let p = this.dataCama.pilfix;
        this.dataCama.prodiFix = this.listOptionsState?.prodi.find((f) => {
          return f?.kode_angka == p;
        });*/
      },
      deep: true,
    },
  },
  methods: {
    listBiayaMhs() {
      this.listMhs = [];
      this.listMhsAll = [];
      this.size =
        this.listMhsAktif?.per_page == this.listMhsAktif?.total
          ? true
          : this.listMhsAktif?.per_page;
      this.page = this.listMhsAktif?.current_page;
      this.listMhsAktif?.data?.forEach((a) => {
        let tagjupiter = a.tagihan; //this.statusTagihan(a.nipd);
        let biayakuliah = this.getBiayaKuliah(a);
        let total = biayakuliah.map((m) => [parseFloat(m?.biaya)]);
        let totaltagihan = total.reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        );
        let beasiswapakai = a.beasiswa.filter((m) => {
          return parseFloat(m?.nominal) > 0 && m?.validasi == 0;
        });
        let tbeasiswa = beasiswapakai.map((m) => [parseFloat(m?.nominal)]);
        let totalbeasiswa = tbeasiswa.reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        );
        this.listMhs.push({
          ...a,
          tagjupiter: a.tagihan,
          status_tagihan: tagjupiter?.Ada_Transaksi,
          status_validasi_tagihan: tagjupiter?.Validasi,
          beasiswa: a.beasiswa.map((f) => {
            return { ...f, pakai: true };
          }),
          biayakuliah: biayakuliah,
          adm: this.biayaAdm,
          totalbiaya: parseFloat(totaltagihan) - parseFloat(totalbeasiswa),
          biayasemestinya: parseFloat(totaltagihan),
          totalrp:
            parseFloat(this.biayaAdm) +
            parseFloat(totaltagihan) -
            parseFloat(totalbeasiswa),
          potongan: parseFloat(totalbeasiswa),
          buat_tagihan:
            (a.kuliah_mhs?.id_stat_mhs === "A" ||
              a.kuliah_mhs?.id_stat_mhs === "M") &&
            biayakuliah?.length == this.jenisBiayaSelected.length,
        });
      });
      this.listMhsAll = this.listMhs;
      this.$emit("listBiayaMhs", this.listMhs);
    },
    getBiayaKuliah(a) {
      let bkul = [];
      this.jenisBiayaSelected.forEach((e) => {
        if (this.biaya?.length > 0) {
          let b = this.biaya.find((c) => {
            return (
              c.id_jenis_biaya == e.id &&
              a[e.sub_jenis]?.includes(c.sub_jenis) &&
              c.tahun == a.tahun
            );
          });
          if (b?.id) {
            bkul.push(b);
          }
        }
      });
      return bkul;
    },
    statusTagihan(nipd) {
      if (this.listTagihanJupiter?.length > 0) {
        let tag = this.listTagihanJupiter?.find((f) => {
          return f?.NPM == nipd;
        });
        return tag;
      } else {
        return null;
      }
    },
    hapusTagihan(btag) {
      this.$emit("hapusBiayaTagihan", btag);
    },

    biayaKuliahDetail() {
      this.listMhsAktif.data?.forEach((a) => {
        this.biayaKuliahMhs(a.kelompok_kelas);
      });
    },
    biayaKuliahMhs(sub_jenis) {
      this.jenisBiayaSelected.forEach((e) => {
        if (this.biaya?.length > 0) {
          let b = this.biaya?.find((f) => {
            return f.id_jenis_biaya == e.id && f.sub_jenis == sub_jenis;
          });
          if (b?.id == null) {
            this.getBiayaDetail(
              this.model.tahun,
              e.id,
              this.model.id_sms,
              sub_jenis
            );
          }
        } else {
          this.getBiayaDetail(
            this.model.tahun,
            e.id,
            this.model.id_sms,
            sub_jenis
          );
        }
      });
    },
    getBiayaDetail(t, b, f, s) {
      return HTTPKEU.post("master/getbiayakuliahdetail", {
        tahun: t,
        id_jenis_biaya: b,
        id_sms: f,
        sub_jenis: s,
      }).then((d) => {
        if (d.status === 200) {
          if (this.biaya?.length > 0) {
            let e = this.biaya?.find((f) => {
              return f.id_jenis_biaya == b && f.sub_jenis == s && f.tahun == t;
            });
            if (e?.id == null) {
              if (d?.data?.id) {
                this.biaya.push(d.data);
              }
            }
          } else {
            if (d?.data?.id) {
              this.biaya.push(d.data);
            }
          }
        }
      });
    },
    pakaiBeasiswa(idx, mhs) {
      let a = this.listMhs.find((f) => {
        return f.nipd == mhs.nipd;
      });
      if (a.beasiswa.length > 0) {
        let biayakuliah = this.getBiayaKuliah(a);
        let total = biayakuliah.map((m) => [parseFloat(m?.biaya)]);
        let totaltagihan = total.reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        );
        let beasiswapakai = a.beasiswa.filter((m) => {
          return parseFloat(m?.nominal) > 0 && m?.pakai === true;
        });
        let tbeasiswa = beasiswapakai.map((m) => [parseFloat(m?.nominal)]);
        let totalbeasiswa = tbeasiswa.reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        );

        a.totalbiaya = parseFloat(totaltagihan) - parseFloat(totalbeasiswa);
        a.biayasemestinya = parseFloat(totaltagihan);
        a.totalrp =
          parseFloat(this.biayaAdm) +
          parseFloat(totaltagihan) -
          parseFloat(totalbeasiswa);
        a.potongan = parseFloat(totalbeasiswa);
      }
      this.$emit("listBiayaMhs", this.listMhs);
    },

    setTagih(mhs) {
      if (mhs.totalbiaya == 0) {
        console.log(mhs.buat_tagihan);
        mhs.buat_tagihan = false;
        alert("Tidak dapat ditagih karena Total Biaya 0 Rupiah");
      } else {
        this.listMhsAll = this.listMhsAll.map((a) => {
          if (mhs.nipd == a.nipd) {
            return {
              ...a,
              buat_tagihan: !mhs.buat_tagihan,
            };
          } else {
            return { ...a };
          }
        });
      }

      this.$emit("listBiayaMhs", this.listMhs);
    },

    listBiayaMhsTagihan() {
      this.listMhs = this.listMhsAll.map((a) => {
        let tagjupiter = this.tagihan;
        if (tagjupiter != null) {
          return {
            ...a,
            tagjupiter: tagjupiter,
            status_tagihan: tagjupiter?.Ada_Transaksi,
            status_validasi_tagihan: tagjupiter?.Validasi,
          };
        } else {
          return {
            ...a,
            status_tagihan: null,
            tagjupiter: tagjupiter,
            status_validasi_tagihan: null,
          };
        }
      });
    },

    reloadData() {
      this.$emit("filterdata", { size: this.size, page: this.page });
    },
    cariMhs() {
      if (this.modeMhsAktif == "A") {
        this.listMhs = this.listMhsAll.filter((f) => {
          return (
            f.kuliah_mhs?.id_stat_mhs == "A" || f.kuliah_mhs?.id_stat_mhs == "M"
          );
        });
      } else if (this.modeMhsAktif == "T") {
        this.listMhs = this.listMhsAll.filter((f) => {
          return f.kuliah_mhs?.id_stat_mhs != "A";
        });
      } else {
        this.listMhs = this.listMhsAll.filter((f) => {
          return f.kuliah_mhs?.id_stat_mhs != "";
        });
      }

      if (this.modekelas == "A") {
        this.listMhs = this.listMhs.filter((f) => {
          return f?.kelompok_kelas == "P" || f?.kelompok_kelas == "S";
        });
      } else if (this.modekelas == "P") {
        this.listMhs = this.listMhs.filter((f) => {
          return f?.kelompok_kelas == "P";
        });
      } else if (this.modekelas == "S") {
        this.listMhs = this.listMhs.filter((f) => {
          return f?.kelompok_kelas == "S";
        });
      } else if (this.modekelas == "K") {
        this.listMhs = this.listMhs.filter((f) => {
          return f?.kelompok_kelas == "K";
        });
      } else if (this.modekelas == "I") {
        this.listMhs = this.listMhs.filter((f) => {
          return f?.kelompok_kelas == "I";
        });
      }

      console.log(this.carinama_txt);
      if (this.carinama_txt != "" && this.carinama_txt != null) {
        this.listMhs = this.listMhs.filter((f) => {
          return f.nm_pd.search(this.carinama_txt) != -1 ? true : false; //|| f.nipd.search(this.carinama_txt);
        });
      } else {
        this.listMhs = this.listMhs.filter(() => {
          return true;
        });
      }
      console.log(this.listMhs);
      this.$emit("listBiayaMhs", this.listMhs);
    },
  },
};
</script>

<style scoped></style>
